export const monospaceStyle = {
  fontFamily: 'monospace',
  color: 'white',
  backgroundColor: 'black',
};

export const listItemStyle = {
  fontFamily: 'monospace',
  color: '#e6e6e6', // Light grey text
  background: '#1C1C1C', // Grey gradient
  borderRadius: '2px',
  margin: '2px 4',
  padding: '5px 20px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Soft shadow
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'transform 0.1s, box-shadow 0.2s, background 0.2s', // Smooth transitions
};

export const listItemHoverStyle = {
  transform: 'scale(.99)', // Slightly larger on hover
  boxShadow: '0 6px 22px rgba(0, 0, 0, 0.2)', // More pronounced shadow on hover
  background: 'linear-gradient(to right, #000000, #777777', // Yellower on hover
  color: 'white', // Slightly lighter text on hover
};