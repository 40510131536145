import React, { useState, useEffect, useRef } from 'react';
import { getNode, getRelationship, updateNode, updateRelationship } from '../services/api';
import NodeDataEditor from './Editor';
import Modal from './Modal';
import { Button, notification, Input, List, Tag } from 'antd';
import { EditOutlined, SwapOutlined, FilterOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import webSocketClient from '../services/websocketClient';

const { TextArea } = Input;

const NodeEditor = ({ cyRef, elements }) => {
  // Existing States
  const [nodeData, setNodeData] = useState(null);
  const [editableNodeData, setEditableNodeData] = useState('');
  const [edgeData, setEdgeData] = useState(null);
  const [editableEdgeData, setEditableEdgeData] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [selectedEdgeId, setSelectedEdgeId] = useState(null);
  const [showPayloadEditor, setShowPayloadEditor] = useState(false);
  const [payloadData, setPayloadData] = useState('');
  const nodeCache = useRef(new Map());
  const edgeCache = useRef(new Map());
  const payloadCache = useRef(new Map());

  // New States for Filters
  const [propertiesFilters, setPropertiesFilters] = useState(() => {
    const storedProperties = localStorage.getItem('properties-filters');
    return storedProperties ? JSON.parse(storedProperties) : [];
  });
  
  const [payloadFilters, setPayloadFilters] = useState(() => {
    const storedPayloads = localStorage.getItem('payload-filters');
    return storedPayloads ? JSON.parse(storedPayloads) : [];
  });
  const [isPropertiesFilterModalOpen, setIsPropertiesFilterModalOpen] = useState(false);
  const [isPayloadFilterModalOpen, setIsPayloadFilterModalOpen] = useState(false);
  const [newPropertiesFilter, setNewPropertiesFilter] = useState('');
  const [newPayloadFilter, setNewPayloadFilter] = useState('');

  // Load filters from localStorage on mount
  // useEffect(() => {
  //   const storedPropertiesFilters = JSON.parse(localStorage.getItem('properties-filters')) || [];
  //   const storedPayloadFilters = JSON.parse(localStorage.getItem('payload-filters')) || [];
  //   setPropertiesFilters(storedPropertiesFilters);
  //   setPayloadFilters(storedPayloadFilters);
  //   console.log('Loaded Properties Filters:', storedPropertiesFilters);
  //   console.log('Loaded Payload Filters:', storedPayloadFilters);
  // }, []);

  // Save filters to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('properties-filters', JSON.stringify(propertiesFilters));
    console.log('Saved Properties Filters:', propertiesFilters);
  }, [propertiesFilters]);

  useEffect(() => {
    localStorage.setItem('payload-filters', JSON.stringify(payloadFilters));
    console.log('Saved Payload Filters:', payloadFilters);
  }, [payloadFilters]);

  // Utility function to deep clone an object
  const deepClone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
  };

  // Utility function to remove keys based on filters
  // const applyFilters = (data, filters) => {
  //   console.log('typeof data:',data);
  //   if (!data || typeof data !== 'object') return data;
  
  //   const removeKeys = (obj, filtersList) => {
  //     if (Array.isArray(obj)) {
  //       return obj.map(item => removeKeys(item, filtersList));
  //     } else if (obj !== null && typeof obj === 'object') {
  //       const newObj = {};
  //       Object.keys(obj).forEach(key => {
  //         // Find filters that apply to this key
  //         const exactFilter = filtersList.find(filter => filter === key);
  //         const nestedFilters = filtersList
  //           .filter(filter => filter.startsWith(`${key}.`))
  //           .map(filter => filter.slice(key.length + 1));
  
  //         if (!exactFilter) {
  //           if (nestedFilters.length > 0) {
  //             newObj[key] = removeKeys(obj[key], nestedFilters);
  //           } else {
  //             newObj[key] = removeKeys(obj[key], []);
  //           }
  //         }
  //         // If exactFilter exists, omit this key entirely
  //       });
  //       return newObj;
  //     }
  //     return obj;
  //   };
  
  //   return removeKeys(JSON.parse(JSON.stringify(deepClone(data))), filters);
  // };
  const applyFilters = (data, filters) => {
    console.log('Applying Filters:', filters);
    console.log('Original Data:', data);
    if (!data || typeof data !== 'object') return data;
  
    const removeKeys = (obj, filtersList) => {
      if (Array.isArray(obj)) {
        return obj.map(item => removeKeys(item, filtersList));
      } else if (obj !== null && typeof obj === 'object') {
        const newObj = {};
        Object.keys(obj).forEach(key => {
          // Find filters that apply to this key
          const exactFilter = filtersList.find(filter => filter === key);
          const nestedFilters = filtersList
            .filter(filter => filter.startsWith(`${key}.`))
            .map(filter => filter.slice(key.length + 1));
  
          if (!exactFilter) {
            if (nestedFilters.length > 0) {
              newObj[key] = removeKeys(obj[key], nestedFilters);
              console.log(`Filtered nested keys for "${key}":`, nestedFilters);
            } else {
              newObj[key] = removeKeys(obj[key], []);
            }
          } else {
            console.log(`Omitting key "${key}" as it matches the filter.`);
          }
          // If exactFilter exists, omit this key entirely
        });
        return newObj;
      }
      return obj;
    };
  
    const filtered = removeKeys(deepClone(data), filters);
    console.log('Filtered Data:', filtered);
    return filtered;
  };
  
  // WebSocket Setup (Modified to Apply Filters)
  useEffect(() => {
    webSocketClient.connect();
  
    const handleWebSocketMessage = (data) => {
      const targetId = data.params.id; // Assuming the incoming message has an `id` property
  
      // Check if this id is present in the elements
      const elementToUpdate = elements.find((element) => element.data.id === targetId);
      console.log('WebSocket - Element to update:', elementToUpdate);
  
      if (elementToUpdate) {
        // Add a yellow ring around the node
        elementToUpdate.last_payload = data; // Assuming `data` is what you want to store
        payloadCache.current.set(targetId, data);
        localStorage.setItem('cytoscape-elements', JSON.stringify(elements));
  
        // Apply Payload Filters before setting payloadData
        const parsedData = parseJSONFields(data);
        const filteredPayload = applyFilters(parsedData, payloadFilters);
        setPayloadData(JSON.stringify(filteredPayload, null, 2));
  
        const targetNode = cyRef.current.$id(targetId);
        if (targetNode) {
          targetNode.addClass('highlight'); // Using a CSS class for styling
  
          // Iterate through all connected edges of the target node
          targetNode.connectedEdges().forEach(edge => {
            const sourceNode = edge.source();
            const targetNodeConnected = edge.target();
  
            // Determine the other node connected by the edge
            const otherNode = sourceNode.id() === targetId ? targetNodeConnected : sourceNode;
  
            // Check if the other node is currently highlighted
            if (otherNode.hasClass('highlight')) {
              edge.addClass('highlight'); // Highlight the edge
            }
          });
        }
  
        // Remove highlight after 5 seconds
        setTimeout(() => {
          if (targetNode) {
            targetNode.removeClass('highlight');
  
            // Iterate through all connected edges of the target node
            targetNode.connectedEdges().forEach(edge => {
              const sourceNode = edge.source();
              const targetNodeConnected = edge.target();
  
              // Determine the other node connected by the edge
              const otherNode = sourceNode.id() === targetId ? targetNodeConnected : sourceNode;
  
              // If the other node is not highlighted, remove highlight from the edge
              if (!otherNode.hasClass('highlight')) {
                edge.removeClass('highlight');
              }
              // If the other node is still highlighted, keep the edge highlighted
            });
          }
        }, 5000); // Remove highlight after 5 seconds
      }
    };
  
    webSocketClient.addListener(handleWebSocketMessage);
  
    return () => {
      webSocketClient.removeListener(handleWebSocketMessage);
    };
  }, [elements, cyRef, payloadFilters]); 

  // Parsing and Stringifying JSON (Unchanged)
  const parseJSONFields = (data) => {
    const parsedData = { ...data };
    const propertiesToParse = ['function_params', 'body', 'headers'];
    if (parsedData.properties) {
      propertiesToParse.forEach((property) => {
        if (parsedData.properties[property]) {
          try {
            parsedData.properties[property] = JSON.parse(parsedData.properties[property]);
          } catch (e) {
            console.error(`Failed to parse ${property}:`, e);
          }
        }
      });
    }
    return parsedData;
  };

  const stringifyJSONFields = (data) => {
    const stringifiedData = { ...data };
    const propertiesToParse = ['function_params', 'body', 'headers'];
    if (stringifiedData.properties) {
      propertiesToParse.forEach((property) => {
        if (stringifiedData.properties[property]) {
          stringifiedData.properties[property] = JSON.stringify(stringifiedData.properties[property], null, 2);
        }
      });
    }
    return stringifiedData;
  };

  // Handling Node Click (Modified to Apply Filters)
  const handleNodeClick = async (nodeId) => {
    setSelectedNodeId(nodeId);
    setSelectedEdgeId(null);

    if (nodeCache.current.has(nodeId)) {
      const cachedNodeData = nodeCache.current.get(nodeId);
      console.log('Cache - Node Data:', cachedNodeData);
      const parsedData = parseJSONFields(cachedNodeData);
      console.log('PARSED: ', parsedData);
      const filteredData = applyFilters(parsedData, propertiesFilters);
      console.log('FILTERED: ', parsedData);

      setNodeData(filteredData);
      setEditableNodeData(JSON.stringify(filteredData, null, 2));
    } else {
      try {
        const response = await getNode(nodeId);
        const parsedData = parseJSONFields(response.data);
        nodeCache.current.set(nodeId, parsedData);
        const filteredData = applyFilters(parsedData, propertiesFilters);
        setNodeData(filteredData);
        console.log('Fetched - Node Data:', parsedData);
        setEditableNodeData(JSON.stringify(filteredData, null, 2));
      } catch (error) {
        console.error('Error fetching node data:', error);
      }
    }

    // Handle Payload Data
    if (payloadCache.current.has(nodeId)) {
      const cachedPayloadData = payloadCache.current.get(nodeId);
      console.log('Cache - Payload Data:', cachedPayloadData);
      const parsedPayload = parseJSONFields(cachedPayloadData);
      const filteredPayload = applyFilters(parsedPayload, payloadFilters);
      setPayloadData(JSON.stringify(filteredPayload, null, 2));
    } else {
      setPayloadData(''); // Clear payload if not present
    }
  };

  // Handling Edge Click (Modified to Apply Filters)
  const handleEdgeClick = async (edgeId) => {
    setSelectedNodeId(null);
    setSelectedEdgeId(edgeId);

    if (edgeCache.current.has(edgeId)) {
      const cachedEdgeData = edgeCache.current.get(edgeId);
      const parsedData = parseJSONFields(cachedEdgeData);
      const filteredData = applyFilters(parsedData, propertiesFilters);
      setEdgeData(filteredData);
      setEditableEdgeData(JSON.stringify(filteredData, null, 2));
    } else {
      const [source, target] = edgeId.split('_');
      try {
        const response = await getRelationship(source, target);
        const parsedData = parseJSONFields(response);
        edgeCache.current.set(edgeId, parsedData);
        const filteredData = applyFilters(parsedData, propertiesFilters);
        setEdgeData(filteredData);
        setEditableEdgeData(JSON.stringify(filteredData, null, 2));
      } catch (error) {
        console.error('Error fetching edge data:', error);
      }
    }
  };

  // Handle Data Change (Unchanged)
  const handleDataChange = (newValue) => {
    if (selectedNodeId) {
      setEditableNodeData(newValue);
    } else if (selectedEdgeId) {
      setEditableEdgeData(newValue);
    }
  };

  // Handle Update Click (Unchanged)
  const handleUpdateClick = () => {
    setIsModalOpen(true);
  };

  // Handle Confirm Update (Modified to Update Cache and Apply Filters)
  const handleConfirmUpdate = async () => {
    try {
      if (selectedNodeId) {
        const updatedData = JSON.parse(editableNodeData);
        const stringifiedData = stringifyJSONFields(updatedData);
        await updateNode(selectedNodeId, stringifiedData);
        nodeCache.current.set(selectedNodeId, stringifiedData);
        const parsedData = parseJSONFields(stringifiedData);
        const filteredData = applyFilters(parsedData, propertiesFilters);
        setNodeData(filteredData);
        setEditableNodeData(JSON.stringify(filteredData, null, 2));
      } else if (selectedEdgeId) {
        const [source, target] = selectedEdgeId.split('_');
        const updatedData = JSON.parse(editableEdgeData);
        const stringifiedData = stringifyJSONFields(updatedData);
        await updateRelationship(source, target, stringifiedData.properties, stringifiedData.label);
        edgeCache.current.set(selectedEdgeId, stringifiedData);
        const parsedData = parseJSONFields(stringifiedData);
        const filteredData = applyFilters(parsedData, propertiesFilters);
        setEdgeData(filteredData);
        setEditableEdgeData(JSON.stringify(filteredData, null, 2));
      }
      setIsModalOpen(false);
      notification.success({
        message: 'Success',
        description: 'Data updated successfully!',
        className: 'custom-notification',
      });
    } catch (error) {
      console.error('Error updating data:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to update data.',
      });
    }
  };

  // Bind Node and Edge Click Events (Unchanged)
  useEffect(() => {
    if (cyRef.current) {
      cyRef.current.unbind('tapend', 'node');
      cyRef.current.on('tapend', 'node', (evt) => {
        const node = evt.target;
        handleNodeClick(node.data('id'));
      });

      cyRef.current.unbind('tapend', 'edge');
      cyRef.current.on('tapend', 'edge', (evt) => {
        const edge = evt.target;
        handleEdgeClick(edge.data('id'));
      });
    }
  }, [cyRef]);

  // Handle Adding New Property Filter
  const handleAddPropertyFilter = () => {
    const trimmed = newPropertiesFilter.trim();
    if (trimmed && !propertiesFilters.includes(trimmed)) {
      setPropertiesFilters([...propertiesFilters, trimmed]);
      setNewPropertiesFilter('');
    }
  };

  // Handle Removing Property Filter
  const handleRemovePropertyFilter = (filter) => {
    setPropertiesFilters(propertiesFilters.filter(f => f !== filter));
  };

  // Handle Adding New Payload Filter
  const handleAddPayloadFilter = () => {
    const trimmed = newPayloadFilter.trim();
    if (trimmed && !payloadFilters.includes(trimmed)) {
      setPayloadFilters([...payloadFilters, trimmed]);
      setNewPayloadFilter('');
    }
  };

  // Handle Removing Payload Filter
  const handleRemovePayloadFilter = (filter) => {
    setPayloadFilters(payloadFilters.filter(f => f !== filter));
  };

  // Re-apply filters whenever filters change or editor view toggles
  useEffect(() => {
    if (selectedNodeId) {
      const originalData = nodeCache.current.get(selectedNodeId);
      if (originalData) {
        const parsedData = parseJSONFields(originalData);
        const filteredData = applyFilters(parsedData, propertiesFilters);
        setNodeData(filteredData);
        setEditableNodeData(JSON.stringify(filteredData, null, 2));
      }
  
      const cachedPayload = payloadCache.current.get(selectedNodeId);
      if (cachedPayload) {
        const parsedPayload = parseJSONFields(cachedPayload);
        const filteredPayload = applyFilters(parsedPayload, payloadFilters);
        setPayloadData(JSON.stringify(filteredPayload, null, 2));
      }
    }
  
    if (selectedEdgeId) {
      const originalData = edgeCache.current.get(selectedEdgeId);
      if (originalData) {
        const parsedData = parseJSONFields(originalData);
        const filteredData = applyFilters(parsedData, propertiesFilters);
        setEdgeData(filteredData);
        setEditableEdgeData(JSON.stringify(filteredData, null, 2));
      }
    }
  }, [propertiesFilters, payloadFilters, selectedNodeId, selectedEdgeId]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
      {showPayloadEditor ? (
        <NodeDataEditor
          value={payloadData}
          title="Payloads"
          onDataChange={handleDataChange}
          readOnly={true}
          theme="cobalt"
        />
      ) : (
        <NodeDataEditor
          title="Properties"
          value={selectedNodeId ? editableNodeData : editableEdgeData}
          onDataChange={handleDataChange}
        />
      )}
      {nodeData && nodeData.last_payload && !showPayloadEditor && (
        <div>
          <h4>Last Payload</h4>
          <pre>{JSON.stringify(nodeData.last_payload, null, 2)}</pre>
        </div>
      )}
      {/* Existing Update and Toggle Payload Buttons */}
      
      {!showPayloadEditor && (
  <Button
    type="primary"
    icon={<EditOutlined />}
    style={{
      position: 'absolute',
      bottom: '100px',
      right: '100px',
      borderRadius: '50%',
      backgroundColor: 'blue',
      borderColor: 'blue',
      height: '50px',
      width: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    onClick={handleUpdateClick}
  />
)}
      <Button
        type="default"
        icon={<SwapOutlined />}
        style={{
          position: 'absolute',
          bottom: '100px',
          right: '160px',
          borderRadius: '50%',
          backgroundColor: 'gray',
          borderColor: 'gray',
          height: '50px',
          width: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={() => setShowPayloadEditor(!showPayloadEditor)}
      />

      {/* Conditional Filter Button */}
      {!showPayloadEditor ? (
        <Button
          type="default"
          icon={<FilterOutlined />}
          style={{
            position: 'absolute',
            bottom: '100px',
            right: '220px', // Position for Properties Filter
            borderRadius: '50%',
            backgroundColor: '#faad14',
            borderColor: '#faad14',
            height: '50px',
            width: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '10px',
          }}
          onClick={() => setIsPropertiesFilterModalOpen(true)}
          title="Filter Properties"
        />
      ) : (
        <Button
          type="default"
          icon={<FilterOutlined />}
          style={{
            position: 'absolute',
            bottom: '100px',
            right: '220px', // Position for Payload Filter (same as Properties for consistency)
            borderRadius: '50%',
            backgroundColor: '#13c2c2',
            borderColor: '#13c2c2',
            height: '50px',
            width: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '10px',
          }}
          onClick={() => setIsPayloadFilterModalOpen(true)}
          title="Filter Payloads"
        />
      )}
      {/* Modal for Confirm Update (Unchanged) */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmUpdate}
      >
        <h2>Confirm Update</h2>
        <p>Are you sure you want to update this {selectedNodeId ? "node's" : "edge's"} data?</p>
      </Modal>

      {/* Modal for Properties Filters */}
     {/* Modal for Properties Filters */}
<Modal
  isOpen={isPropertiesFilterModalOpen}
  onClose={() => setIsPropertiesFilterModalOpen(false)}
  onConfirm={() => setIsPropertiesFilterModalOpen(false)}
>
  <h2>Filter Properties</h2>
  <Input
    placeholder="Enter key to filter (e.g., properties.system)"
    value={newPropertiesFilter}
    onChange={(e) => setNewPropertiesFilter(e.target.value)}
    onPressEnter={handleAddPropertyFilter}
    addonAfter={<Button icon={<PlusOutlined />} onClick={handleAddPropertyFilter} />}
  />
        <List
          header={<div>Current Filters</div>}
          bordered
          dataSource={propertiesFilters}
          renderItem={item => (
            <List.Item
              actions={[
                <CloseOutlined key="delete" onClick={() => handleRemovePropertyFilter(item)} />
              ]}
            >
              <Tag color="blue">{item}</Tag>
            </List.Item>
          )}
          style={{ marginTop: '20px', maxHeight: '200px', overflowY: 'auto' }}
        />
      </Modal>

      {/* Modal for Payload Filters */}
      <Modal
  isOpen={isPayloadFilterModalOpen}
  onClose={() => setIsPayloadFilterModalOpen(false)}
  onConfirm={() => setIsPayloadFilterModalOpen(false)}
>
  <h2>Filter Payloads</h2>
  <Input
    placeholder="Enter key to filter (e.g., data.value)"
    value={newPayloadFilter}
    onChange={(e) => setNewPayloadFilter(e.target.value)}
    onPressEnter={handleAddPayloadFilter}
    addonAfter={<Button icon={<PlusOutlined />} onClick={handleAddPayloadFilter} />}
  />
        <List
          header={<div>Current Filters</div>}
          bordered
          dataSource={payloadFilters}
          renderItem={item => (
            <List.Item
              actions={[
                <CloseOutlined key="delete" onClick={() => handleRemovePayloadFilter(item)} />
              ]}
            >
              <Tag color="cyan">{item}</Tag>
            </List.Item>
          )}
          style={{ marginTop: '20px', maxHeight: '200px', overflowY: 'auto' }}
        />
      </Modal>
    </div>
  );
};

export default NodeEditor;
