import { useState, useEffect } from 'react';
import { getLabelSuggestions, createNode } from '../../services/api';
import { getColorByLabel,getIconByLabel, getShapeByLabel } from '../../utils/utils';
import { setNewNodeModalVisible, newNodeModalVisible } from '../Graph';

const useNodeModal = ({ newNodeModalVisible, setElements, setNewNodeModalVisible }) => {
  const [labelSuggestions, setLabelSuggestions] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [newNodeData, setNewNodeData] = useState({ labels: [''], properties: {} });

  const handleNewNodeSubmit = async () => {
    newNodeData.labels = [selectedLabel];
    const newNode = await createNode(newNodeData);
    if (newNode) {
      newNode.style = {
        'background-color': getColorByLabel(newNodeData.labels[0] || 'default'),
        'background-image': getIconByLabel(newNodeData.labels[0] || 'default'),

        'shape': getShapeByLabel(newNodeData.labels[0] || 'default') // Set shape based on label
    };
    setElements((prevElements) => [...prevElements, newNode]);
    }
    setNewNodeModalVisible(false);
  };

  return {
    labelSuggestions,
    selectedLabel,
    setSelectedLabel,
    newNodeData,
    setNewNodeData,
    handleNewNodeSubmit,
    newNodeModalVisible
  };
};

export default useNodeModal;