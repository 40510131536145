// export const getColorByLabel = (label) => {
//   const labelColors = {
//     'Root': 'red',          // Root
//     'Branch': 'yellow',     // Branch
//     'Leaf': 'green',        // Leaf
//     'Pipeline': 'lavender',     // Pipeline
//     'Bridge': 'brown',      // Bridge
//     'Joint': 'white',      // Joint
//     'Code': 'cyan',         // Code
//     'User': 'orange',       // User
//     'Detector': 'magenta',  // Detector
//     'Engine': 'gold',     // Engine
//     'Gateway': 'silver',      // Gateway
//     'Document': 'pink',     // Document
//     'Action': 'lime',       // Action
//     'Gather': 'white',       // Gather
//     'Paragraph': 'grey', // Paragraph
//     'Head': 'coral',        // Head
//     'Question': 'red',          // Root
//     'Answer': 'yellow'        // Root

//   };

//   return labelColors[label] || 'gray';
// };
// export const getShapeByLabel = (label) => {
//   const labelShapes = {
//     'Root': 'ellipse',        // Root
//     'Branch': 'ellipse',      // Branch
//     'Leaf': 'ellipse',        // Leaf
//     'Pipeline': 'ellipse',     // Pipeline
//     'Bridge': 'ellipse',      // Bridge
//     'Joint': 'triangle',       // Joint
//     'Code': 'ellipse',        // Code
//     'User': 'ellipse',        // User
//     'Detector': 'ellipse',    // Detector
//     'Engine': 'ellipse',      // Engine
//     'Gateway': 'ellipse',     // Gateway
//     'Document': 'ellipse',    // Document
//     'Action': 'ellipse',      // Action
//     'Gather': 'triangle',      // Gather
//     'Paragraph': 'ellipse',    // Paragraph
//     'Head': 'ellipse',        // Head
//     'Question': 'ellipse',    // Question
//     'Answer': 'ellipse'       // Answer
//   };

//   return labelShapes[label] || 'circle'; // default shape is circle
// };

// utils.js

import React from 'react';
import { 
  HomeOutlined,
  BranchesOutlined,
  NodeIndexOutlined,
  DeploymentUnitOutlined,
  AimOutlined,
  InteractionOutlined,
  CodeOutlined,
  UserOutlined,
  RadarChartOutlined,
  RobotOutlined,
  GatewayOutlined,
  FileTextOutlined,
  PlayCircleOutlined,
  FundProjectionScreenOutlined,
  OrderedListOutlined,
  BulbOutlined,
  QuestionOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import { renderToStaticMarkup } from 'react-dom/server';

/**
 * Utility function to convert an Ant Design Icon component to a URL-encoded SVG Data URL.
 * @param {React.Element} iconComponent - The Ant Design Icon React component.
 * @returns {string} - The URL-encoded SVG Data URL.
 */
const getAntdIconDataURL = (iconComponent) => {
  // Render the React icon component to a static SVG string
  let svgString = renderToStaticMarkup(iconComponent);

  // Ensure the SVG has the XML namespace
  if (!svgString.includes('xmlns="http://www.w3.org/2000/svg"')) {
    svgString = svgString.replace(
      /^<svg/,
      '<svg xmlns="http://www.w3.org/2000/svg"'
    );
  }

  // Remove any line breaks or unnecessary spaces
  svgString = svgString.replace(/\s+/g, ' ').trim();

  // URL-encode the SVG string
  const encodedSvg = encodeURIComponent(svgString)
    .replace(/'/g, '%27')
    .replace(/"/g, '%22');

  // Return the Data URL
  return `data:image/svg+xml;utf8,${encodedSvg}`;
};

/**
 * Function to get color based on node label.
 * @param {string} label - The label of the node.
 * @returns {string} - The corresponding color.
 */
export const getColorByLabel = (label) => {
  const labelColors = {
    'Root': 'red',          // Root
    'Branch': 'yellow',     // Branch
    'Leaf': 'green',        // Leaf
    'Pipeline': 'lavender', // Pipeline
    'Bridge': 'brown',      // Bridge
    'Joint': 'white',       // Joint
    'Code': 'cyan',         // Code
    'User': 'orange',       // User
    'Detector': 'magenta',  // Detector
    'Engine': 'red',       // Engine
    'Gateway': 'silver',    // Gateway
    'Document': 'pink',     // Document
    'Action': 'lime',       // Action
    'Gather': 'white',      // Gather
    'Paragraph': 'grey',    // Paragraph
    'Head': 'coral',        // Head
    'Question': 'red',      // Question
    'Answer': 'yellow'      // Answer
  };

  return labelColors[label] || 'gray';
};

/**
 * Function to get shape based on node label.
 * @param {string} label - The label of the node.
 * @returns {string} - The corresponding shape.
 */
export const getShapeByLabel = (label) => {
  const labelShapes = {
    'Root': 'ellipse',
    'Branch': 'ellipse',
    'Leaf': 'ellipse',
    'Pipeline': 'ellipse',
    'Bridge': 'ellipse',
    'Joint': 'triangle',
    'Code': 'ellipse',
    'User': 'ellipse',
    'Detector': 'ellipse',
    'Engine': 'ellipse',
    'Gateway': 'ellipse',
    'Document': 'ellipse',
    'Action': 'ellipse',
    'Gather': 'triangle',
    'Paragraph': 'ellipse',
    'Head': 'ellipse',
    'Question': 'ellipse',
    'Answer': 'ellipse'
  };

  return labelShapes[label] || 'circle'; // default shape is circle
};

/**
 * Function to get icon Data URL based on node label using Ant Design Icons.
 * @param {string} label - The label of the node.
 * @returns {string} - The Base64 Data URL of the corresponding SVG icon.
 */

export const getIconByLabel = (label) => {
 const labelIcons = {
   'Root': 'https://img.icons8.com/pixels/32/mind-map.png',           // Root
   'Branch': 'https://img.icons8.com/pixels/32/sakura.png',       // Branch
   'Leaf': 'https://img.icons8.com/pixels/32/maple-leaf.png',           // Leaf
   'Pipeline': 'https://img.icons8.com/pixels/32/module.png',   // Pipeline
   'Bridge': 'https://img.icons8.com/pixels/32/api.png',       // Bridge
   'Joint': 'https://img.icons8.com/pixels/32/tree-structure.png',         // Joint
   'Code': 'https://img.icons8.com/pixels/32/source-code.png',           // Code
   'User': 'https://img.icons8.com/pixels/32/homer-simpson.png',           // User
   'Detector': 'https://img.icons8.com/pixels/32/detective.png',   // Detector
  //  'Engine': 'https://img.icons8.com/material-outlined/24/message-bot.png',
         // Engine
   'Engine': 'https://img.icons8.com/pixels/32/bot.png',

   'Gateway': '/icons/gateway.png',     // Gateway
   'Document': 'https://img.icons8.com/pixels/32/document-1.png',   // Document
   'Action': 'https://img.icons8.com/pixels/32/3d-glasses.png',       // Action
   'Gather': 'https://img.icons8.com/pixels/32/collect.png',       // Gather
   'Paragraph': 'https://img.icons8.com/pixels/32/quote-left.png', // Paragraph
   'Head': 'https://img.icons8.com/pixels/32/retro-robot.png',           // Head
   'Question': 'https://img.icons8.com/pixels/32/decision.png',   // Question
   'Answer': 'https://img.icons8.com/pixels/32/tasklist.png',       // Answer
 };

 return labelIcons[label] || '/icons/default.png'; // Default icon
};