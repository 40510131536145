import React, { useState, useRef, useEffect } from 'react';
import { Modal, Select, AutoComplete, List, Radio, Button, Tabs, Input } from 'antd';
import { monospaceStyle, listItemStyle, listItemHoverStyle } from '../utils/styles';
import './customModalStyles.css';
import {EventHandlers} from './EventHandlers'


const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

const ModalComponent = ({
  isModalVisible,
  setIsModalVisible,
  handleCancel,
  searchTerm,
  setSearchTerm,
  labelOptions,
  handleLabelSelect,
  propertyOptions,
  fetchSuggestions,
  valueOptions,
  handleValueChange,
  searchResults,
  handleSearchResultClick,
  showSelectionOption,
  selectionOption,
  setSelectionOption,
  handleSelectNode,
  handleExecuteQuery, // New prop for handling query execution
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLabelChanged, setIsLabelChanged] = useState(false);
  const [isPropertyChanged, setIsPropertyChanged] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [customQuery, setCustomQuery] = useState(''); // State for custom query
  // const [isModalVisible, setIsModalVisible] = useState(false);


  // Refs for Select components
  const labelSelectRef = useRef(null);
  const propertySelectRef = useRef(null);
  const valueAutoCompleteRef = useRef(null);

  const onHandleSelectNode = async () => {
    setLoading(true);
    try {
      await handleSelectNode();
    } finally {
      setLoading(false);
    }
  };

  const onHandleExecuteQuery = async () => {
    setLoading(true);
    try {
      await handleExecuteQuery(customQuery);
      setIsModalVisible(false);
    } finally {
      setLoading(false);
    }
  };

  const handleEnterKey = (event, currentRef, nextRef, clearFunc, isChanged) => {
    const { key } = event;
    if (key === 'Enter') {
      event.preventDefault();
      if (isChanged) {
        clearFunc('');
        setIsLabelChanged(currentRef === labelSelectRef);
        setIsPropertyChanged(currentRef === propertySelectRef);
      } else {
        nextRef.current.focus();
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleEnterKey(event, labelSelectRef, propertySelectRef, (value) => setSearchTerm(prev => ({ ...prev, label: value })), searchTerm.label !== '' && isLabelChanged);
      handleEnterKey(event, propertySelectRef, valueAutoCompleteRef, (value) => setSearchTerm(prev => ({ ...prev, property: value })), searchTerm.property !== '' && isPropertyChanged);
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [labelOptions, propertyOptions, searchTerm, handleLabelSelect, setSearchTerm, isLabelChanged, isPropertyChanged]);

  return (
    <Modal
      title="Find A Source Node To Change The View"
      open={isModalVisible}
      onCancel={handleCancel}
      footer={null}
    >
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <TabPane tab="Search Nodes" key="1">
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Select
              ref={labelSelectRef}
              placeholder="Select Label"
              onChange={(value) => {
                handleLabelSelect(value);
                setSearchTerm((prev) => ({ ...prev, label: value }));
                setIsLabelChanged(true);
              }}
              onSearch={(value) => fetchSuggestions('label', value)}
              value={searchTerm.label}
              showSearch
              onKeyDown={(event) => handleEnterKey(event, labelSelectRef, propertySelectRef, (value) => setSearchTerm(prev => ({ ...prev, label: value })), searchTerm.label !== '' && isLabelChanged)}
            >
              {labelOptions.map((label) => (
                <Option key={label} value={label}>
                  {label}
                </Option>
              ))}
            </Select>

            <Select
              ref={propertySelectRef}
              placeholder="Select Property"
              onChange={(value) => {
                setSearchTerm((prev) => ({ ...prev, property: value }));
                setIsPropertyChanged(true);
              }}
              onSearch={(value) => fetchSuggestions('property', value, searchTerm.label)}
              value={searchTerm.property}
              showSearch
              onKeyDown={(event) => handleEnterKey(event, propertySelectRef, valueAutoCompleteRef, (value) => setSearchTerm(prev => ({ ...prev, property: value })), searchTerm.property !== '' && isPropertyChanged)}
            >
              {propertyOptions.map((property) => (
                <Option key={property} value={property}>
                  {property}
                </Option>
              ))}
            </Select>

            <AutoComplete
              ref={valueAutoCompleteRef}
              placeholder="Value"
              value={searchTerm.value}
              onChange={handleValueChange}
              options={valueOptions.map((item) => ({ value: item }))}
            />

            <div style={{ maxHeight: '200px', overflowY: 'auto', ...monospaceStyle }}>
              <List
                dataSource={searchResults}
                renderItem={(item, index) => (
                  <List.Item
                    style={
                      hoveredIndex === index || selectedIndex === index
                      ? { ...listItemStyle, ...listItemHoverStyle }
                      : listItemStyle
                    }
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    onClick={() => {
                      setSelectedIndex(index);
                      handleSearchResultClick(item.result.id);
                    }}
                  >
                    {item.result.name}
                  </List.Item>
                )}
              />
            </div>

            {showSelectionOption && (
              <>
                <Radio.Group
                  onChange={(e) => setSelectionOption(e.target.value)}
                  value={selectionOption}
                  style={{ ...monospaceStyle }}
                >
                  <Radio value="new" style={{ color: 'white' }}>Create New Scene</Radio>
                  <Radio value="add" style={{ color: 'white' }}>Add To Existing Scene</Radio>
                  <Radio value="single" style={{ color: 'white' }}>Add A Single Node To The Scene</Radio>
                </Radio.Group>
                <Button type="primary" onClick={onHandleSelectNode} loading={loading}>Load Scene</Button>
              </>
            )}
          </div>
        </TabPane>
        <TabPane tab="Execute Query" key="2">
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <TextArea
              placeholder="Enter your Cypher query here"
              value={customQuery}
              onChange={(e) => setCustomQuery(e.target.value)}
              autoSize={{ minRows: 8 }}
              style={{ fontFamily: 'monospace' }}
            />
            <Button type="primary" onClick={onHandleExecuteQuery} loading={loading}>Execute Query</Button>
          </div>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default ModalComponent;