import { useState, useEffect } from 'react';
import {
  getLabelSuggestions,
  getPropertySuggestions,
  searchNodes,
  getTreeForNode,
  getNode
} from '../../services/api';
import { getColorByLabel, getIconByLabel,getShapeByLabel } from '../../utils/utils';

const useElements = () => {
  const [elements, setElements] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState({ label: '', property: '', value: '' });
  const [labelOptions, setLabelOptions] = useState([]);
  const [propertyOptions, setPropertyOptions] = useState([]);
  const [valueOptions, setValueOptions] = useState([]);
  const [selectionOption, setSelectionOption] = useState('new');
  const [showSelectionOption, setShowSelectionOption] = useState(false);
  const [selectedNodeId, setSelectedNodeId] = useState(null);


  useEffect(() => {
    try {
      const savedElements = JSON.parse(localStorage.getItem('cytoscape-elements'));
      if (savedElements && Array.isArray(savedElements)) {
        setElements(savedElements);
        console.log('Loaded elements from localStorage:', savedElements);
      } else {
        console.log('No valid elements found in localStorage');
      }
    } catch (error) {
      console.error('Error loading elements from localStorage:', error);
    }
  }, []);

  useEffect(() => {
    if (elements.length > 0) {
      localStorage.setItem('cytoscape-elements', JSON.stringify(elements));
      console.log('Saved elements to localStorage:', elements);
    }
  }, [elements]);

  const showModal = async () => {
    try {
      const response = await getLabelSuggestions();
      setLabelOptions(response.data);
      setIsModalVisible(true);
    } catch (error) {
      console.error('Error fetching label suggestions:', error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShowSelectionOption(false);
    setSelectedNodeId(null);
  };

  const handleLabelSelect = (label) => {
    setSearchTerm({ ...searchTerm, label });
    fetchSuggestions('property', '', label);
  };

  const fetchSuggestions = async (type, searchText, label) => {
    try {
      if (type === 'property') {
        const response = await getPropertySuggestions(label, searchText);
        setPropertyOptions(response.data);
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const handleValueChange = async (value) => {
    setSearchTerm({ ...searchTerm, value });
    try {
      const response = await searchNodes(searchTerm.label, searchTerm.property, value);
      const resData = response.data['results'];
      setSearchResults(Array.isArray(resData) ? resData : []);
    } catch (error) {
      console.error('Error fetching search results:', error);
      setSearchResults([]);
    }
  };

  const handleSearchResultClick = (nodeId) => {
    setShowSelectionOption(true);
    setSelectedNodeId(nodeId);
  };
  const handleSelectNode = async () => {
    if (!selectedNodeId) return;
    try {
        let response, data;
    
        // Check the selectionOption and call the appropriate function
        if (selectionOption === 'single') {
            response = await getNode(selectedNodeId);
            const res = {"data":{"nodes": [response.data], "relationships": []}}
            // response.data.relationships = [];
            response = res
        } else {
            response = await getTreeForNode(selectedNodeId);
        }
        
        data = response.data;
        const nodes = data.nodes.map((node) => {
            const id = node.id || (node.node && node.node.id);
            if (!id) {
                console.warn('Node with undefined id:', node);
            }
            return {
                data: { id: id, labels: node.labels || [], ...node },
                style: { 'background-color': getColorByLabel(node.labels[0] || 'default'), 
                  'background-image': getIconByLabel(node.labels[0] || 'default'),

                  'shape': getShapeByLabel(node.labels[0] || 'default') // Set shape based on label

                 }
            };
        });
        const nodeMap = nodes.reduce((map, node) => {
            if (node.data.id) {
                map[node.data.id] = node;
            } else {
                console.warn('Node with undefined id:', node);
            }
            return map;
        }, {});

        // Add conditional check for empty relationships
        const edges = (data.relationships && data.relationships.length > 0)
            ? data.relationships
                .filter(rel => {
                    const valid = nodeMap[rel.source] && nodeMap[rel.target];
                    if (!valid) {
                        console.warn(`Skipping edge with non-existent node: ${rel.source} -> ${rel.target}`);
                    }
                    return valid;
                })
                .map(rel => ({
                    data: {
                        id: `${rel.source}_${rel.target}`,
                        source: rel.source,
                        target: rel.target,
                        label: rel.label,
                        properties: rel.properties
                    }
                }))
            : [];  // Return an empty array if there are no relationships
        
        console.log('DATA RELS:', data.relationships)
        
        if (selectionOption === 'add' || 'single') {
            setElements((prevElements) => [...prevElements, ...nodes, ...edges]);
        } else {
            setElements([...nodes, ...edges]);
        }
        setIsModalVisible(false);
        setShowSelectionOption(false);
        setSelectedNodeId(null);
    } catch (error) {
        console.error('Error fetching tree for node:', error);
    }
};


  return {
    elements,
    setElements,
    isModalVisible,
    setIsModalVisible,
    searchResults,
    setSearchResults,
    searchTerm,
    setSearchTerm,
    labelOptions,
    setLabelOptions,
    propertyOptions,
    setPropertyOptions,
    valueOptions,
    setValueOptions,
    selectionOption,
    setSelectionOption,
    showSelectionOption,
    setShowSelectionOption,
    selectedNodeId,
    setSelectedNodeId,
    fetchSuggestions,
    handleValueChange,
    handleSearchResultClick,
    handleSelectNode,
    handleCancel,
    handleLabelSelect,
    showModal,
  };
};

export default useElements;