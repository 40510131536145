const WEBSOCKET_URL = process.env.REACT_APP_WS_BASE_URL; // Replace with your WebSocket server URL
class WebSocketClient {
  constructor() {
    this.socket = null;
    this.listeners = [];
  }

  connect() {
    const userId = localStorage.getItem('userId');
    const apiKey = localStorage.getItem('apiKey');

    if (!userId || !apiKey) {
      console.error("User ID and API Key must be stored in local storage.");
      return;
    }

    // Close the existing connection before creating a new one
    if (this.socket) {
      this.disconnect();
    }

    this.socket = new WebSocket(`${WEBSOCKET_URL}/ws?user_id=${userId}&api_key=${apiKey}`);

    this.socket.onopen = () => {
      console.log('WebSocket connection established');
    };

    this.socket.onmessage = (message) => {
      const data = JSON.parse(message.data);
      console.log('data:', data);
      this.listeners.forEach((listener) => listener(data));
    };

    this.socket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }

  addListener(listener) {
    this.listeners.push(listener);
  }

  removeListener(listener) {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }
}

const webSocketClient = new WebSocketClient();
export default webSocketClient;