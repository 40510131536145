import React from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-terminal';
import 'ace-builds/src-noconflict/theme-cobalt'; // Import the cobalt theme

const NodeDataEditor = ({title, value, onDataChange, readOnly = false, theme = 'terminal' }) => {
  return (
    <div style={{ marginLeft: '2px', width: '60vh', height: '80vh', marginRight: '21px' }}>
      <h2 style={{ fontSize: 22, fontWeight: 9}}>{title}:</h2>
      <AceEditor
        mode="json"
        theme={theme}
        name="nodeDataEditor"
        value={value}
        wrapEnabled={true}
        onChange={onDataChange}
        editorProps={{ $blockScrolling: true }}
        setOptions={{
          readOnly: readOnly,
          highlightActiveLine: true,
          wrapEnabled: true,
          highlightGutterLine: true,
          useWorker: false,
        }}
        style={{ width: '100%', height: 'calc(100% - 25px)' }} // Adjust height to fit within container minus header    
      />
    </div>
  );
};

export default NodeDataEditor;