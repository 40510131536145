import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';  // Assuming you are using `uuid` library to generate UUIDs
import  webSocketClient  from './websocketClient'
let apiKey = null;
let api = null;
const assignApiKey = (newApiKey) => {
  console.log('hello', newApiKey)
  apiKey = newApiKey;
  api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      'api-key': apiKey,
      'x-alt': 'true'
    }
  });
  // Reconnect WebSocket
  webSocketClient.connect();
};
const handleError = (error) => {
    if (error.response) {
      // Request made and server responded
      console.error('Error Response:', error.response);
      if (error.response.status === 404) {
        console.error('Error 404: Not Found');
        return { status: 404, message: 'Not Found', data: error.response.data };
      } else if (error.response.status === 422) {
        console.error('Error 422: Unprocessable Entity', error.response.data);
        return { status: 422, message: 'Unprocessable Entity', data: error.response.data };
      } else {
        console.error('Error:', error.response.status, error.response.data);
        return { status: error.response.status, message: error.response.data.message || 'An error occurred', data: error.response.data };
      }
    } else if (error.request) {
      // Request made but no response received
      console.error('Error Request:', error.request);
      return { status: 500, message: 'No response received from server', data: null };
    } else {
      // Something happened in setting up the request
      console.error('Error', error.message);
      return { status: 500, message: error.message, data: null };
    }
  };
// Node APIs

// const generateUniqueIdentifier = (originalId) => `${}`;


const duplicateNode = async (nodeId) => {
  try {
      const node = await getNode(nodeId);
      if (!node) throw new Error('Node not found');

      const duplicatedNode = {
          ...node.data,
          id: uuidv4(),
          name: node.data.name ? `${node.data.name}_dup` : undefined,
          slug: node.data.slug ? `${node.data.slug.replace(/_/g, '-')}-dup` : undefined,
      };

      return await api.post('/nodes/duplicate', duplicatedNode);
  } catch (error) {
      return handleError(error);
  }
};

const duplicateEdge = async (startNodeId, endNodeId, newStartNodeId, newEndNodeId) => {
  try {
      const edge = await getRelationship(startNodeId, endNodeId);
      if (!edge) throw new Error('Edge not found');

    // Create a new edge object with the new keys
    const duplicatedEdge = {
      ...edge,
      new_start: newStartNodeId,
      new_end: newEndNodeId
    };

      return await api.post('/relationship/duplicate', duplicatedEdge);
  } catch (error) {
      return handleError(error);
  }
};

// Add these newly created methods to your exports
export {
  assignApiKey,
  duplicateNode,
  duplicateEdge,
    // ... other exports
};


export const createNode = async (data) => {
    try {
        return await api.post('/node', data);
    } catch (error) {
        handleError(error);
    }
};

export const getNode = async (nodeId) => {
    try {
        return await api.get(`/node/${nodeId}`);
    } catch (error) {
        handleError(error);
    }
};

export const getNodes = async () => {
    try {
        return await api.get('/nodes');
    } catch (error) {
        handleError(error);
    }
};

export const getRelatedNodes = async (nodeId) => {
    try {
        return await api.get(`/node/${nodeId}/related-nodes`);
    } catch (error) {
        handleError(error);
    }
};

export const updateNode = async (nodeId, data) => {
    try {
        return await api.put(`/node/${nodeId}`, data);
    } catch (error) {
        handleError(error);
    }
};

export const deleteNode = async (nodeId) => {
    try {
        return await api.delete(`/node/${nodeId}`);
    } catch (error) {
        handleError(error);
    }
};

export const addNodeLabel = async (nodeId, label) => {
    try {
        return await api.post(`/node/${nodeId}/label`, { label });
    } catch (error) {
        handleError(error);
    }
};

export const removeNodeLabel = async (nodeId, label) => {
    try {
        return await api.delete(`/node/${nodeId}/label`, { label });
    } catch (error) {
        handleError(error);
    }
};
  
  // Relationship APIs
  export const createRelationship = async (data) => {
    try {
      const response = await api.post('/relationship', data, {
      });
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  };
  
  export const getRelationship = async (startNodeId, endNodeId) => {
    try {
      const response = await api.get('/relationship', {
        params: {
          start_node_id: startNodeId,
          end_node_id: endNodeId,
        },
      });
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  };
  
  export const updateRelationship = async (startNodeId, endNodeId, data, label) => {
    console.log('trying to put: ', startNodeId, endNodeId, data, label);
    try {
      // if (typeof data === 'string') {
      //   try {
      //       data = JSON.parse(data);
      //   } catch (error) {
      //       console.error('Failed to parse `data` as JSON:', error);
      //       return handleError(new Error('Invalid JSON string for `data` property.'));
      //   }
      // }
      const response = await api.put('/relationship', {
        properties: data,
        label: label,
      }, {
        params: {
          start_node_id: startNodeId,
          end_node_id: endNodeId,
        },
      });
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  };
  
  export const deleteRelationship = async (startNodeId, endNodeId) => {
    try {
      const response = await api.delete('/relationship', {
        params: {
          start_node_id: startNodeId,
          end_node_id: endNodeId,
        },
      });
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  };

  // search api


export const searchNodes = (label, property, value) => {
  const response =  api.get('/search', { params: { label, property, value } });
  return response;
};

export const getTreeForNode = (root_id) => {
  return api.get(`/nodes/get-tree`, { params: { root_id } });
};
export const getLabelSuggestions = () => {
  return api.get(`/suggestions/label`);
};
export const getEdgeLabelSuggestions = () => {
  return api.get(`/suggestions/edge`);
};
export const getPropertySuggestions = (label, query) => {
  const url = label ? `/suggestions/property?label=${label}&query=${query}` : `/suggestions/property?query=${query}`;
  return api.get(url);
};


export const executeCypherQuery = async (query) => {
  try {
    const response = await api.post('/nodes/execute-query', {
      query,
    });
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};