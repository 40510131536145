// export const cytoscapeStyles = [
//   {
//     selector: 'node',
//     style: {
//       'background-color': 'rgba(128, 128, 128, 0.5)', // Set gray with 50% opacity
//       'label': 'data(labels)',
//       'font-family': 'monospace',
//       'font-size': '5px',
//       'outline-color': 'black',
//       'text-valign': 'center',
//       'text-halign': 'center',
//       // Default border properties if desired (optional)
//       'border-width': '7px', // Default to no border
//     },
//   },
//   {
//     selector: 'edge',
//     style: {
//       'label': 'data(label)',
//       'text-rotation': 'autorotate',
//       'font-family': 'monospace',
//       'font-size': '4px',
//       'color': 'white',
//       'line-color': 'gray',
//       'target-arrow-color': 'gray',
//       'target-arrow-shape': 'triangle',
//       'curve-style': 'bezier',
//       'width': 1,
//     },
//   },
//   {
//     selector: '.highlight', // Adding a new style for highlighted nodes
//     style: {
//       // Use outline to create distance
//       'outline-color': 'white',
//       'outline-width': '3px',
//       'outline-opacity': '1', // Slightly transparent for a better appearance
//       'background-color': 'rgba(0, 0, 0, 1)', // Maintain the original background color with opacity
//       'box-shadow': '0 0 30px white', // Add the glow effect
//       'z-index': '9999', // Make sure the node is on top
//     },
//   },
//   {
//     selector: 'edge.highlight',
//     style: {
//       'line-color': 'yellow',           // Change the edge line color to yellow
//       'width': '4px',                   // Increase the edge width for visibility
//       'target-arrow-color': 'yellow',   // Change the target arrow color to yellow
//       'source-arrow-color': 'yellow',   // Change the source arrow color to yellow
//       'opacity': 1,                     // Ensure the edge is fully opaque
//       'curve-style': 'bezier',          // Optional: Enhance the edge's curve style
//       'transition-property': 'line-color, width, target-arrow-color, source-arrow-color, opacity',
//       'transition-duration': '500ms',   // Smooth transition for highlighting
//     }
//   },
// ];
// cytoscapeStyles.js

export const cytoscapeStyles = [
  {
    selector: 'node',
    style: {
      'background-color': 'rgba(128, 128, 128, 0.5)', // Set gray with 50% opacity
      'background-image': 'data(background-image)',                // Use the 'icon' data attribute
      // 'background-fit': 'cover',                        // Ensure the icon covers the node
      'background-width': '70%',
      'background-height': '70%',
      'background-clip': 'none',
      'label': '',                                      // Remove the label text
      'font-family': 'monospace',
      'font-size': '5px',
      'outline-color': 'white',
      'text-valign': 'center',
      'text-halign': 'center',
      'width': '100',                                  // Set node width
      'height': '100',  
      'border-width': '7px',                            // Default to no border
    },
  },
  {
    selector: 'edge',
    style: {
      'label': 'data(label)',
      'text-rotation': 'autorotate',
      'font-family': 'monospace',
      'font-size': '10px',
      'color': 'white',
      'line-color': 'gray',
      'target-arrow-color': 'gray',
      'target-arrow-shape': 'triangle',
      'curve-style': 'bezier',
      'width': 5,
    },
  },
  {
    selector: 'node.highlight', // Specific selector for highlighted nodes
    style: {
      'border': '2px solid yellow', // Corrected syntax
      'outline-color': 'white',
      'outline-width': '10px',
      'outline-opacity': '1',
      'background-color': 'rgba(0, 0, 0, 1)',
      'box-shadow': '0 0 30px white',
      'z-index': '9999',
      'transition-property': 'border, outline-color, outline-width, background-color, box-shadow',
      'transition-duration': '500ms',
    },
  },
  {
    selector: 'edge.highlight',
    style: {
      'line-color': 'yellow',           // Change the edge line color to yellow
      'width': '8px',                   // Increase the edge width for visibility
      'target-arrow-color': 'yellow',   // Change the target arrow color to yellow
      'source-arrow-color': 'yellow',   // Change the source arrow color to yellow
      'opacity': 1,                     // Ensure the edge is fully opaque
      'curve-style': 'bezier',          // Optional: Enhance the edge's curve style
      'transition-property': 'line-color, width, target-arrow-color, source-arrow-color, opacity',
      'transition-duration': '500ms',   // Smooth transition for highlighting
    }
  },
];