import React, { useEffect, useState, useRef } from 'react';
import CytoscapeComponent from 'react-cytoscapejs';
import cytoscape from 'cytoscape';
import dagre from 'cytoscape-dagre';
// import klay from 'cytoscape-klay';

import cp from 'cytoscape';

import contextMenus from 'cytoscape-context-menus';
import 'cytoscape-context-menus/cytoscape-context-menus.css';
import './customContextMenuStyles.css';
import { debounce } from 'lodash'; // Make sure to properly import this
import {
  getLabelSuggestions,
  duplicateNode,
  duplicateEdge,
  createNode,
  getEdgeLabelSuggestions,
  createRelationship,
  deleteRelationship,
  deleteNode,
  assignApiKey,
  executeCypherQuery
} from '../services/api';
import NodeEditor from './EditorHandler';
import EventHandlers from './EventHandlers';
import ModalComponent from './SceneModalComponent';
import { Layout, Button, Modal, Form, Input, Select } from 'antd';
import { EyeOutlined, LockOutlined } from '@ant-design/icons';
import useElements from './hooks/useElements';
import useNodeModal from './hooks/useNewNodeModal';
import { getColorByLabel, getIconByLabel, getShapeByLabel } from '../utils/utils';
import { cytoscapeStyles } from '../utils/cytoscapeStyles';
import { monospaceStyle } from '../utils/styles';

cytoscape.use(dagre);
cytoscape.use(contextMenus);

const { Content } = Layout;

const Graph = () => {
  const {
    elements,
    setElements,
    isModalVisible,
    setIsModalVisible,
    searchResults,
    searchTerm,
    setSearchTerm,
    labelOptions,
    propertyOptions,
    valueOptions,
    selectionOption,
    setSelectionOption,
    showSelectionOption,
    fetchSuggestions,
    handleValueChange,
    handleSearchResultClick,
    handleSelectNode,
    handleCancel,
    handleLabelSelect,
    showModal
  } = useElements();
  const [newNodeModalVisible, setNewNodeModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

      // Use the custom hook for node modal
      const {
        setSelectedLabel,
        selectedLabel,
        newNodeData,
        setNewNodeData,

        // handleNewNodeSubmit
      } = useNodeModal({ newNodeModalVisible, setElements, setNewNodeModalVisible });    

  const cyRef = useRef(null);
  const [cyInitialized, setCyInitialized] = useState(false);

  const [labelSuggestions, setLabelSuggestions] = useState([]);
  const [newEdgeModalVisible, setNewEdgeModalVisible] = useState(false);
  const [edgeLabelSuggestions, setEdgeLabelSuggestions] = useState([]);
  const [selectedEdgeLabel, setSelectedEdgeLabel] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [elementsToDelete, setElementsToDelete] = useState([]);
  const [selectedNodesOrder, setSelectedNodesOrder] = useState([]);

  const [apiKeyModalVisible, setApiKeyModalVisible] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [userId, setUserId] = useState('');
  const handleExecuteQuery = async (query) => {
    setLoading(true);
    try {
      const response = await executeCypherQuery(query);
      const data = response; // Assuming response is { nodes: [...], relationships: [...] }
  
      // Process nodes
      const nodes = data.nodes.map((node) => {
        const id = node.id; // Use node.id directly
        if (!id) {
          console.warn('Node with undefined id:', node);
          return null; // Skip nodes without an id
        }
        return {
          data: {
            id: id,
            labels: node.labels || [],
            ...node.properties, // Spread properties
          },
          style: {
            'background-color': getColorByLabel(node.labels[0] || 'default'),
            'background-image': getIconByLabel(node.labels[0] || 'default'),

            'shape': getShapeByLabel(node.labels[0] || 'default'),
          },
        };
      }).filter(Boolean); // Remove null entries
  
      // Process relationships
      const edges = data.relationships.map((rel) => ({
        data: {
          id: `${rel.source}_${rel.target}_${rel.label}`, // Unique identifier for the edge
          source: rel.source,
          target: rel.target,
          label: rel.label,
          ...rel.properties, // Spread properties if needed
        },
      }));
  
      // First, add all nodes
      setElements((prevElements) => {
        // Filter out existing nodes to prevent duplicates
        const newNodes = nodes.filter(
          (node) => !prevElements.some((el) => el.data.id === node.data.id)
        );
        return [...prevElements, ...newNodes];
      });
  
      // Then, add all edges
      setElements((prevElements) => {
        // Filter out existing edges to prevent duplicates
        const newEdges = edges.filter(
          (edge) => !prevElements.some((el) => el.data.id === edge.data.id)
        );
        return [...prevElements, ...newEdges];
      });
  
    } catch (error) {
      console.error('Error executing query:', error);
    } finally {
      setLoading(false);
    }
  };
  
  
    const handleNewNodeSubmit = async () => {
    newNodeData.labels = [selectedLabel];
    const newNode = await createNode(newNodeData);
    if (newNode) {
      newNode.style = {
        'background-color': getColorByLabel(newNodeData.labels[0] || 'default'),
        'background-image': getIconByLabel(newNodeData.labels[0] || 'default'),
        'shape': getShapeByLabel(newNodeData.labels[0] || 'default') // Set shape based on label
    }
    setElements((prevElements) => [...prevElements, newNode]);
    }
    setNewNodeModalVisible(false);
  };
  useEffect(() => {
    const storedApiKey = localStorage.getItem('apiKey');
    const storedUserId = localStorage.getItem('userId');
    if (storedApiKey && storedUserId) {
      assignApiKey(storedApiKey);
      setApiKey(storedApiKey);
      setUserId(storedUserId);
    }
  }, []);
  const handleApiKeySave = () => {
    localStorage.setItem('apiKey', apiKey);
    localStorage.setItem('userId', userId);
    assignApiKey(apiKey);
    console.log('API Key:', apiKey);
    console.log('User ID:', userId);
    setApiKeyModalVisible(false);
    // You can also call an API to validate the credentials here
  };

  useEffect(() => {
    const fetchEdgeLabelSuggestions = async () => {
      try {
        const suggestions = await getEdgeLabelSuggestions();
        console.log('SUGGESTIONS: ', suggestions);
        if (Array.isArray(suggestions.data)) {
          setEdgeLabelSuggestions(suggestions.data);
        } else {
          console.error('Label suggestions is not an array:', suggestions.data);
        }
      } catch (error) {
        console.error('Error fetching label suggestions:', error);
      }
    };
    if (newEdgeModalVisible) {
      fetchEdgeLabelSuggestions();
    }
  }, [newEdgeModalVisible]);

  useEffect(() => {
    const fetchNodeLabelSuggestions = async () => {
      try {
        const suggestions = await getLabelSuggestions();
        console.log('SUGGESTIONS: ', suggestions);
        if (Array.isArray(suggestions.data)) {
          setLabelSuggestions(suggestions.data);
        } else {
          console.error('Label suggestions is not an array:', suggestions.data);
        }
      } catch (error) {
        console.error('Error fetching label suggestions:', error);
      }
    };
    if (newNodeModalVisible) {
      fetchNodeLabelSuggestions();
    }
  }, [newNodeModalVisible]);



  const handleLabelChange = (value) => {
    setSelectedLabel(value);
  };

  const handleCreateEdgeSubmit = async () => {
    if (selectedNodesOrder.length === 2) {
      const sourceNodeId = selectedNodesOrder[0];
      const targetNodeId = selectedNodesOrder[1];

      const newEdgeData = {
        start_id: sourceNodeId,
        end_id: targetNodeId,
        type: selectedEdgeLabel,
        properties: {}
      };

      try {
        const response = await createRelationship(newEdgeData);
        const newEdge = response;
        if (newEdge) {
          const edgeElement = {
            group: 'edges',
            data: {
              id: `${newEdge.source}_${newEdge.target}`,
              source: newEdge.source,
              target: newEdge.target,
              label: newEdge.label,
              properties: newEdge.properties
            }
          };
          setElements((prevElements) => [...prevElements, edgeElement]);
        }
      } catch (error) {
        console.error('Error creating edge:', error);
      }
    }
    setNewEdgeModalVisible(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      const elementsToRemove = [...elementsToDelete];

      // Collect all connected edges for nodes to be deleted
      elementsToDelete.forEach(element => {
        if (element.group === 'nodes') {
          const connectedEdges = cyRef.current.edges(`[source = "${element.data.id}"], [target = "${element.data.id}"]`);
          connectedEdges.forEach(edge => elementsToRemove.push(edge.json()));
        }
      });

      for (const element of elementsToRemove) {
        if (element.group === 'edges') {
          await deleteRelationship(element.data.source, element.data.target);
        } else if (element.group === 'nodes') {
          await deleteNode(element.data.id);
        }
      }

      setElements((prevElements) =>
        prevElements.filter((element) =>
          !elementsToRemove.some(el => el.data.id === element.data.id)
        )
      );
    } catch (error) {
      console.error('Error deleting elements:', error);
    }
    setDeleteModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
  };

  useEffect(() => {
    if (cyRef.current) {
      const cy = cyRef.current;

      if (!cy.contextMenus) {
        contextMenus(cytoscape);
      }

      const instance = cy.contextMenus({
        menuItems: [
          {
            id: 'remove',
            content: 'Remove From The Scene',
            tooltipText: 'Remove',
            selector: 'node:selected, edge:selected',
            onClickFunction: (event) => {
              const selectedIds = cy.elements(':selected').map(ele => ele.id());
              setElements((prevElements) =>
                prevElements.filter((element) =>
                  !selectedIds.includes(element.data.id) &&
                  !selectedIds.some(id => id === element.data.source || id === element.data.target)
                )
              );
            },
            hasTrailingDivider: true,
            coreAsWell: false
          },
          {
            id: 'delete',
            content: 'Permanently Delete Data',
            tooltipText: 'Delete',
            selector: 'node:selected, edge:selected',
            onClickFunction: (event) => {
              const selectedElements = cy.elements(':selected').map(ele => ele.json());
              setElementsToDelete(selectedElements);
              setDeleteModalVisible(true);
            },
            hasTrailingDivider: true,
            coreAsWell: false
          },
          {
            id: 'duplicate',
            content: 'Duplicate',
            tooltipText: 'Duplicate',
            selector: 'node:selected, edge:selected',
            onClickFunction: async (event) => {
              const selectedElements = cy.elements(':selected');
              let newElements = [];
              const newNodeMap = {};
              for (const element of selectedElements.filter(el => el.isNode())) {
                const oldId = element.id();
                const newNode = await duplicateNode(oldId);
                newNode.style = {
                  'background-color': getColorByLabel(newNodeData.labels[0] || 'default'),
                  'background-image': getIconByLabel(newNodeData.labels[0] || 'default'),
                  'shape': getShapeByLabel(newNodeData.labels[0] || 'default') // Set shape based on label
              };
                const newId = newNode.data.id;
                newNodeMap[oldId] = newId;
                newElements.push(newNode);
              }
              for (const element of selectedElements.filter(el => el.isEdge())) {
                const oldSource = element.data('source');
                const oldTarget = element.data('target');
                const oldProps = element.data('properties');
                const newSource = newNodeMap[oldSource];
                const newTarget = newNodeMap[oldTarget];
                if (newSource && newTarget) {
                  const newEdge = await duplicateEdge(oldSource, oldTarget, newSource, newTarget);
                  newEdge.id = `${newSource}_${newTarget}`;
                  newElements.push(newEdge);
                }
              }
              setElements((prevElements) => [...prevElements, ...newElements]);
            },
            hasTrailingDivider: true,
            coreAsWell: false
          },
          {
            id: 'new-node',
            content: 'New Node',
            tooltipText: 'Create a new node',
            selector: 'core',
            onClickFunction: (event) => {
              setNewNodeModalVisible(true);
              // setLabelSuggestions(['hh']);
            },
            hasTrailingDivider: true,
            coreAsWell: true,
            show: () => cy.$('node:selected').length === null
          },
          {
            id: 'create-edge',
            content: 'Create Edge',
            tooltipText: 'Create an edge between selected nodes',
            selector: 'node:selected',
            onClickFunction: (event) => {
              if (cy.$('node:selected').length === 2) {
                setNewEdgeModalVisible(true);
              }
            },
            hasTrailingDivider: true,
            coreAsWell: false,
            show: () => cy.$('node:selected').length === 2
          }
        ],
        menuItemClasses: ['custom-menu-item', 'custom-menu-item:hover'],
        contextMenuClasses: ['custom-context-menu']
      });

      const updateMenuItemsVisibility = () => {
        instance.menuItems.forEach(item => {
          if (item.show) {
            if (item.show()) {
              instance.showMenuItem(item.id);
            } else {
              instance.hideMenuItem(item.id);
            }
          }
        });
      };

      const updateSelectionOrder = () => {
        const currentSelectedNodes = cy.nodes(':selected');
        const currentSelectedNodeIds = currentSelectedNodes.map(node => node.data('id'));

        setSelectedNodesOrder(prevSelectedNodesOrder => {
          // Add only new selections to the order, keeping the old ones
          const newSelectedNodesOrder = Array.isArray(prevSelectedNodesOrder) ? [...prevSelectedNodesOrder] : [];
          console.log('ORDER', newSelectedNodesOrder)
          currentSelectedNodeIds.forEach(id => {
            if (!newSelectedNodesOrder.includes(id)) {
              newSelectedNodesOrder.push(id);
            }
          });
          console.log('ORDER', newSelectedNodesOrder)

          // Remove deselected nodes from the order
          return newSelectedNodesOrder.filter(id => currentSelectedNodeIds.includes(id));
        });
      };

      const debouncedUpdateSelectionOrder = debounce(updateSelectionOrder, 100);
      const handleSelectUnselectNode = () => debouncedUpdateSelectionOrder();

      cy.on('select', 'node', handleSelectUnselectNode);
      cy.on('unselect', 'node', handleSelectUnselectNode);
      cy.on('select unselect', 'node edge', updateMenuItemsVisibility);

      // cy.layout({ name: 'dagre' }).run();
      cy.layout({ name: 'dagre' }).run();

      setCyInitialized(true);

      return () => {
        cy.off('select', 'node', handleSelectUnselectNode);
        cy.off('unselect', 'node', handleSelectUnselectNode);
        cy.off('select unselect', 'node edge', updateMenuItemsVisibility);
      };
    }
  }, [elements, labelSuggestions, edgeLabelSuggestions]);

  return (
    <Layout style={{ display: 'flex', height: '90vh', width: '100%', backgroundColor: 'black' }}>
      <Content style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'black', position: 'relative' }}>
        <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%', backgroundColor: 'black' }}>
          <CytoscapeComponent
            elements={elements}
            style={{ width: '60%', height: '100%', ...monospaceStyle, backgroundImage: 'radial-gradient(circle, #444 1px, rgba(0,0,0,0) 1px)', backgroundSize: '20px 20px' }}
            layout={{ name: 'cose' }}
            stylesheet={cytoscapeStyles}
            cy={(cy) => { cyRef.current = cy; }}
          />
          {cyInitialized && (
            <div style={{ width: '40%', ...monospaceStyle }}>
              <NodeEditor cyRef={cyRef} elements={elements} />
            </div>
          )}
        </div>
        <EventHandlers cyRef={cyRef} />
        <Button
          icon={<EyeOutlined />}
          style={{ position: 'absolute', borderRadius: '50%', top: 20, left: 20 }}
          onClick={showModal}
        />
        <Button
          icon={<LockOutlined />}
          style={{ position: 'absolute', borderRadius: '50%', top: 20, left: 60 }}
          onClick={() => setApiKeyModalVisible(true)}
        />
        <ModalComponent
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}          
          handleCancel={handleCancel}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          labelOptions={labelOptions}
          handleLabelSelect={handleLabelSelect}
          propertyOptions={propertyOptions}
          fetchSuggestions={fetchSuggestions}
          valueOptions={valueOptions}
          handleValueChange={handleValueChange}
          searchResults={searchResults}
          handleSearchResultClick={handleSearchResultClick}
          showSelectionOption={showSelectionOption}
          selectionOption={selectionOption}
          setSelectionOption={setSelectionOption}
          handleSelectNode={handleSelectNode}
          handleExecuteQuery={handleExecuteQuery} // Pass the new handler

        />
        <Modal
          title="Create New Node"
          visible={newNodeModalVisible}
          onCancel={() => setNewNodeModalVisible(false)}
          onOk={handleNewNodeSubmit}
        >
          <Form layout="vertical">
            <Form.Item label="Label">
              <Select
                placeholder="Select a label"
                style={{ width: '100%', marginTop: 16 }}
                onChange={handleLabelChange}
              >
                {labelSuggestions.map((label) => (
                  <Select.Option key={label} value={label}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Properties">
              <Input
                placeholder='{"key1":"value1", "key2": "value2"}'
                value={
                  (() => {
                    try {
                      if (typeof newNodeData.properties === 'string') {
                        const parsed = JSON.parse(newNodeData.properties);
                        return Object.keys(parsed).length === 0 ? '' : newNodeData.properties;
                      } else if (typeof newNodeData.properties === 'object') {
                        const stringified = JSON.stringify(newNodeData.properties);
                        return stringified === '{}' ? '' : stringified;
                      }
                    } catch (error) {
                      return 'Err';
                    }
                    return newNodeData.properties;
                  })()
                }
                onChange={(e) => setNewNodeData({ ...newNodeData, properties: e.target.value })}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Create New Edge"
          visible={newEdgeModalVisible}
          onCancel={() => setNewEdgeModalVisible(false)}
          onOk={handleCreateEdgeSubmit}
        >
          <Form layout="vertical">
            <Form.Item label="Edge Label">
              <Select
                placeholder="Select an edge label"
                style={{ width: '100%', marginTop: 16 }}
                onChange={(value) => setSelectedEdgeLabel(value)}
              >
                {edgeLabelSuggestions.map((label) => (
                  <Select.Option key={label} value={label}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Delete Elements"
          visible={deleteModalVisible}
          onOk={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        >
          <p>Are you sure you want to delete the selected elements?</p>
        </Modal>
              
        <Modal
          title="API Key & User ID"
          visible={apiKeyModalVisible}
          onOk={handleApiKeySave}
          onCancel={() => setApiKeyModalVisible(false)}
        >
          <Form layout="vertical">
            <Form.Item label="Please enter your API key to grant access.">
              <Input
                type="text"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Please enter your User ID to grant access.">
              <Input
                type="text"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
              />
            </Form.Item>
          </Form>
        </Modal>
                  
      </Content>
    </Layout>
  );
};

export default Graph;