// EventHandlers.js
import React, { useEffect } from 'react';
import { getColorByLabel,getIconByLabel,  getShapeByLabel } from '../utils/utils';

const EventHandlers = ({ cyRef }) => {
  useEffect(() => {
    if (cyRef.current) {
      cyRef.current.on('select', 'node', (evt) => {
        evt.target.style('background-color', 'blue');
      });
      cyRef.current.on('unselect', 'node', (evt) => {
        const label = evt.target.data('labels');
        evt.target.style('background-color', getColorByLabel(label[0]));
        evt.target.style('background-image', getIconByLabel(label[0]));

      });
      cyRef.current.on('select', 'edge', (evt) => {
        evt.target.style({
          'line-color': 'blue',
          'target-arrow-color': 'blue',
          'source-arrow-color': 'blue'
        });
      });
      cyRef.current.on('unselect', 'edge', (evt) => {
        evt.target.style({
          'line-color': 'gray',
          'target-arrow-color': 'gray',
          'source-arrow-color': 'gray'
        });
      });
    }
  }, [cyRef]);

  return null;
};

export default EventHandlers;
