import React from 'react';
import { Modal as AntdModal, Button } from 'antd';
import 'antd/dist/reset.css'; // Ensure to import Ant Design styles
import './customModalStyles.css'; // Import custom styles

const Modal = ({ isOpen, onClose, onConfirm, children }) => {
  return (
    <AntdModal
      visible={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose} type="default">
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onConfirm}>
          Confirm
        </Button>,
      ]}  
    >
      {children}
    </AntdModal>
  );
};

export default Modal;